import { useEffect } from 'react';
import { useAppDispatch } from 'hooks/hooks';
import { ChainNames } from 'config';
import { useAccount, useDisconnect } from 'wagmi';

export const useNetwork = () => {
	const { disconnect } = useDisconnect();
	const dispatch = useAppDispatch();
	const networkId = (
		(localStorage.getItem('networkId') as ChainNames) || ''
	).toLowerCase();
	const atvNetwork = 'Base';
	const selectedNetwork = atvNetwork.toLowerCase();
	const { chain, isConnected } = useAccount();

	useEffect(() => {
		if (atvNetwork && chain && atvNetwork !== chain.name && isConnected) {
			disconnect();
		}
	}, [atvNetwork, chain, isConnected, disconnect, selectedNetwork]);

	useEffect(() => {
		if (
			networkId !== '' &&
			selectedNetwork !== '' &&
			networkId !== selectedNetwork
		) {
			disconnect();
		}
	}, [dispatch, atvNetwork, disconnect, networkId, selectedNetwork]);
};
