import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Modal } from '@mui/material';

import { css } from '@emotion/react';
import { styled } from '@mui/material/styles';
import { ConnectionButton } from 'components/Navbar/ConnectButton';
import { Link, useLocation } from 'react-router-dom';
import { Gutter } from 'components/Gutter';
import { getTotalWallet } from 'apis';
import GroupTwoToneIcon from '@mui/icons-material/GroupTwoTone';
import Tooltip from '@mui/material/Tooltip';

const Row = styled('div')`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const StyledLink = styled(Link)(({ theme }) => ({
	color: '#3A3A3A',
	margin: theme.spacing(2),
	cursor: 'pointer',
	textDecoration: 'none',
	marginRight: 2,
	'&:hover': {
		color: '#2960F8',
	},

	'&.active': {
		color: '#2960F8',
	},
}));

const navContainer = css`
	height: 65px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 40px;
`;

const NavContainer = styled(Box)`
	${navContainer}
`;

const DemoButton = styled(Button)`
	font-size: 1rem;
	font-weight: bold;
	margin-right: 10px;
`;

const TotalUser = styled('div')`
	display: flex;
	flex-direction: row;
	border-radius: 50px;
	background-color: #235ee122;
	font-weight: 600;
	font-size: 20px;
	padding: 5px 20px;
	color: #235ee1;
`;

const TextButton = styled(Button)`
	border-radius: 50px;
	background-color: #235ee1dd;
	font-weight: 600;
	font-size: 20px;
	height: 32px;
	padding: 5px 20px;
	box-shadow: 0 0;

	:hover {
		background-color: #235ee1ff;
		box-shadow: 0 0;
	}
`;

const LoomModal = () => {
	const [open, setOpen] = useState(false);

	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '80%',
		maxHeight: '80%',
		bgcolor: 'background.paper',
		boxShadow: 24,
		p: 4,
	};

	return (
		<div>
			<DemoButton color="primary" onClick={handleOpen}>
				See Demo
			</DemoButton>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="Explainer video"
				aria-describedby="Explainer video"
			>
				<Box sx={style}>
					<iframe
						src="https://www.loom.com/embed/d7caf17eb26a464288d7f33e002e50ca?sid=eb1ae28a-0dc8-4195-a186-875aa78a1593"
						allowFullScreen
						title="Explainer video"
						style={{ width: '100%', height: '600px' }}
					></iframe>
				</Box>
			</Modal>
		</div>
	);
};

const Navbar: React.ComponentType = () => {
	const [totalWallet, setTotalWallet] = useState<number>(0);
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const network = queryParams.get('network');
	const isMevmNetwork = !network
		? false
		: network.includes('movement_parthenon') ||
		  network.includes('movement_porto');

	const fetchTotalWallet = useCallback(async () => {
		try {
			const res = await getTotalWallet();
			setTotalWallet(res.totalWallets);
		} catch (e) {
			//
		}
	}, []);

	useEffect(() => {
		fetchTotalWallet();
	}, [fetchTotalWallet]);

	return (
		<>
			<NavContainer
				sx={{ display: 'flex', justifyContent: 'space-between' }}
			>
				<Row>
					<img
						src="/images/rndm-logo.png"
						height={36}
						alt={'movement'}
					/>
					<Box width={64} />
					{/*{NAVBAR.map((d) => (
						<StyledLink key={d.to} to={d.to}>
							{d.name}
						</StyledLink>
					))}*/}
				</Row>

				<Box display="flex" alignItems="center">
					{totalWallet > 0 && (
						<Tooltip title="Daily Users" arrow>
							<TotalUser>
								<GroupTwoToneIcon />
								{totalWallet}
							</TotalUser>
						</Tooltip>
					)}
					<Gutter gap={1} />
					<ConnectionButton />
				</Box>
			</NavContainer>
		</>
	);
};

export { Navbar };
