import React from 'react';
import { Vault } from 'data/Vaults';
import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { TvlGraph } from 'pages/Landing/components/TVLGraph';

const Card = styled('div', {
	shouldForwardProp: (prop) => prop !== 'disabled',
})<{ disabled: boolean }>`
	border-radius: 8px;
	width: 100%;
	overflow: hidden;

	:hover {
		cursor: ${(props) => (!props.disabled ? 'pointer' : 'no-drop')};
	}
`;

const Row = styled('div')`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const Header = styled(Row)`
	width: 100%;
	background: #e6e6e9;
	padding: 8px;
	justify-content: space-between;
`;

const Body = styled('div')`
	padding: 10px 8px;
	background: #e6e6e966;
`;

const Item = styled(Row)`
	background: #e6e9f2;
	justify-content: space-between;
	padding: 6px 10px;
	border-radius: 4px;
`;

const Column = styled(Row)`
	flex-direction: column;
	background: #e6e9f2;
	padding: 6px 10px;
	border-radius: 4px;
	max-width: 500px;
`;

const SpaceBetween = styled(Row)`
	justify-content: space-between;
	width: 100%;
`;

export const ItemCard: React.ComponentType<Vault> = ({ ...props }) => {
	const selectedVault = useSelector((state: RootState) => state.vault);
	const selectedYield =
		selectedVault.name === props.name
			? props.exchanger.find(
					(f) =>
						f.value.toLowerCase() ===
						selectedVault.exchange.toLowerCase(),
			  )
			: props.exchanger[0];

	const selectedExchanger = selectedVault.exchanger.find(
		(f) => f.value.toLowerCase() === selectedVault.exchange.toLowerCase(),
	);

	return (
		<Card
			style={{
				opacity: props.isActive ? 1 : 0.5,
				border: `2px solid ${
					props.name === selectedVault.name
						? '#235EE1'
						: 'transparent'
				}`,
			}}
			onClick={() => {
				if (props.isActive) {
				}
			}}
			disabled={!props.isActive}
		>
			<Header>
				<Row>
					<img
						src={props.logo}
						alt={props.name}
						width={25}
						height={25}
					/>
					<Box width={8} />
					<Typography variant={'subtitle1'}>{props.name}</Typography>
				</Row>
				{!props.isActive && (
					<Row>
						<Typography variant={'subtitle1'} color={'#235ee1'}>
							Soon
						</Typography>
					</Row>
				)}
			</Header>
			<Body>
				<Typography variant={'subtitle1'}>
					{props.shortDescription}
				</Typography>
				<Box height={10} />
				<Item>
					{props.name === 'Ganesh' ? (
						<>
							<Typography variant={'h6'}>
								{selectedYield?.estApy}
							</Typography>
							<Typography>Est. Yield</Typography>
						</>
					) : (
						<>
							<Typography variant={'subtitle1'}>
								RNDM points
							</Typography>
						</>
					)}
				</Item>
				<Box height={10} />
				<Column>
					<SpaceBetween
						style={{
							width: '100%',
						}}
					>
						<Row>
							<img
								src={'/images/crypto/usdc.png'}
								height={18}
								width={18}
								alt={'USDC'}
							/>
							<Box width={10} />
							<Typography variant={'body1'}>USDC</Typography>
						</Row>
					</SpaceBetween>
					<Box height={15} />
					<Row style={{ alignItems: 'center' }}>
						<Typography
							variant={'body1'}
							color={'#666677'}
							display={'inline'}
						>
							{props.name === 'Ganesh'
								? 'Total TVL '
								: 'Total Vol'}
						</Typography>
						<TvlGraph
							dataSource={
								(props.name === selectedVault.name
									? selectedExchanger?.totalTvl
									: props.exchanger[0]?.totalTvl) || []
							}
						/>
					</Row>
				</Column>
			</Body>
		</Card>
	);
};
